export const goldylocksModules =
    {
        state() {
            return {
                modules: [
                    "core",
                    "vendas",
                    "compras",
                    "cc",
                    "crm",
                    "medicina",
                    "pos",
                    "prestashop",
                    "preparacao",
                    "delivery",
                    "entregas",
                    "lotes",
                    "equipamentos",
                    "entregas",
                    "avencas",
                    "fitofarmacos",
                    "importacao_solidworks",
                    "auto_medicao",
                    "transportadoras",
                    "transportadoras_gigante_express"
                ]
            }
        }
        ,
        mutations: {}
        ,
        getters: {
            modules(state: any) {
                return state.modules;
            }
        }
        ,
        actions: {
            existsModule(context: any, moduleName: string) {
                return (typeof context.getters.modules[moduleName] !== "undefined")
            }
        }
    }
