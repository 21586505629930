<template>
  <div>
    <div>
      <p class="has-text-weight-bold is-size-4">Novo Goldyocks</p>
      <p class="is-size-6">Crie aqui uma nova aplicação.</p>
    </div>
    <!-- CARD -->
    <form @submit.prevent="createGoldylocks">

      <!-- DATA CARD -->
      <div class="card mt-6">
        <div class="card-content">
          <div class="columns">
            <div class="column is-6-desktop">
              <div class="field">
                <label class="label">NIF</label>
                <div class="control">
                  <input v-model="dadosEmpresa.nif_goldylocks" class="input has-text-centered" type="number" placeholder="999999999" required>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6-desktop">
              <div class="field">
                <label class="label">Alias</label>
                <div class="control">
                  <input v-model="dadosEmpresa.alias_goldylocks" class="input has-text-centered" type="text" placeholder="aminhaempresa" required>
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Nome empresa</label>
            <div class="control">
              <input v-model="dadosEmpresa.nome_goldylocks" class="input" type="text" placeholder="Empresa demonstrativa, Lda" required>
            </div>
          </div>
          <div class="field">
            <label class="label">Morada</label>
            <div class="control">
              <input v-model="dadosEmpresa.morada_goldylocks" class="input" type="text" placeholder="Rua de teste, Nº 85" required>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">Código Postal</label>
                <div class="control">
                  <input v-model="dadosEmpresa.cp_goldylocks" class="input" type="text" placeholder="0000-000" required>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Localidade</label>
                <div class="control">
                  <input v-model="dadosEmpresa.localidade_goldylocks" class="input" type="text" placeholder="Cidade" required>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <!-- CARD EMAIL -->
      <div class="card mt-3">
        <div class="card-content">
          <div class="field">
            <label class="label">Email do responsável</label>
            <div class="control">
              <input v-model="dadosEmpresa.email" class="input" type="email" placeholder="gerencia@empresa.pt" required>
            </div>
          </div>
        </div>
      </div>

      <!-- PLANS CARD -->
      <div class="card mt-3">
        <div class="card-header">
          <div class="card-header-title">Plano a utilizar</div>
        </div>
        <div class="card-content">
          <div class="columns">
            <div class="column">
              <div class="select">
                <select v-model="dadosEmpresa.plan_id">
                  <option v-for="plan of onlyPlans" :key="plan.codigo_plano" :value="plan.id_plano">{{ plan.nome_plano }}</option>
                </select>
              </div>
              <div v-if="dadosEmpresa.plan_id > 0" class="mt-3">
                <p>Valor mensal: &euro; {{ selectedPlanInfo['valor_mensal'] }}</p>
                <p>Valor anual: &euro; {{ selectedPlanInfo['valor_anual'] }}</p>
              </div>
            </div>
            <div class="column content" v-if="dadosEmpresa.plan_id > 0">
              <div v-html="selectedPlanInfo['designacao_html']"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- CARD BUTTONS -->
      <div class="card mt-3">
        <div class="card-content has-text-right">
          <button class="button is-success" :class="{'is-loading': isCreating}"><strong>Criar</strong>&nbsp;Goldylocks</button>
        </div>
      </div>


    </form>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CreateCompanyForm",
  data() {
    return {
      dadosEmpresa: {
        alias_goldylocks: "",
        email: "",
        nome_goldylocks: "",
        morada_goldylocks: "",
        cp_goldylocks: "",
        localidade_goldylocks: "",
        nif_goldylocks: "",
        plan_id: 0
      },
      minimumAliasChars: 8,
      isCreating: false,
      plans: [],
    }
  },
  computed: {
    validAlias() {
      return (this.dadosEmpresa.alias_goldylocks.length >= this.minimumAliasChars)
    },
    onlyPlans() {
      return this.plans.filter(plan => {
        return plan['extensao'] === "0"
      });
    },
    selectedPlanInfo() {
      let selectedPlan = this.onlyPlans.filter(plan => {
        return (plan['id_plano'] === this.dadosEmpresa.plan_id)
      })

      return selectedPlan[0]
    },
  },
  methods: {
    createGoldylocks() {
      if (this.validAlias) {
        this.isCreating = true;
        this.$store.dispatch('createNewCompany', this.dadosEmpresa)
            .then(() => {
              this.isCreating = false;
              this.dadosEmpresa = {
                alias_goldylocks: "",
                email: "",
                nome_goldylocks: "",
                morada_goldylocks: "",
                cp_goldylocks: "",
                localidade_goldylocks: "",
                nif_goldylocks: "",
                plan_id: 0
              }

              Swal.fire({
                icon: `success`,
                html: `Pedido de criação submetido com <strong>sucesso</strong>!<br><span>Receberá um email com as credenciais de acesso dentro de momentos.</span>`,
                showConfirmButton: true,
                showCancelButton: false,
                confirmButtonText: `OK`,
                confirmButtonColor: `#3ec487`,
              })
            })
            .catch(() => {
              this.isCreating = false;
            })
      } else {
        alert(`O alias deve conter pelo menos ${this.minimumAliasChars} carateres!`);
      }
    },
    fetchPlans() {
      this.$store.dispatch('fetchPlans')
          .then(plans => {
            this.plans = plans;
          })
    }
  },
  mounted() {
    this.fetchPlans();
  }
})
</script>

<style scoped>

</style>