
import {defineComponent} from 'vue'
import PasswordForceCheck from "@/components/PasswordForceCheck.vue";

export default defineComponent({
  name: "UserRegistration",
  components: {PasswordForceCheck},
  data() {
    return {
      email: "",
      isLoading: false,
      alreadyExistantEmail: false,
      canCheckPasswords: false,
      minimumChars: 8,
      password: "",
      passwordConfirmation: "",
    }
  },
  computed: {
    passwordChars(): number {
      return this.password.length
    },
    enoughPasswordChars(): boolean {
      return (this.password.length >= this.minimumChars)
    },
    samePassword(): boolean {
      return (this.password === this.passwordConfirmation)
    }
  },
  methods: {
    register() {
      this.canCheckPasswords = true;
      this.alreadyExistantEmail = false;
      this.isLoading = true;

      if (this.samePassword && this.enoughPasswordChars) {
        // register user
        fetch(`/api/users/register`, {
          method: 'POST',
          body: JSON.stringify({
            user_email: this.email,
            password: this.password
          })
        }).then(res => {
          this.isLoading = false;
          if (res.status === 200) {
            // registation success
            this.$router.push('/registrationsuccess');
          } else if (res.status === 403) {
            // email already exists
            this.alreadyExistantEmail = true;
          } else {
            // other errors
          }
        })
      } else {
        this.isLoading = false
      }
    }
  }
})
