<template>
  <!--  <Navbar></Navbar>-->
  <div class="dashboardGrid">
    <SideMenu class="sidemenu"></SideMenu>
    <div class="dashboardContent">
      <!--      -->
      <a @click="toggleSideMenu" class="is-hidden-tablet"><span class="fa fa-bars"></span></a>
      <router-view name="dashboardContent"></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from '../components/Navbar'
import SideMenu from '../components/SideMenu'

export default {
  name: "Dashboard",
  components: {
    Navbar,
    SideMenu
  },
  methods: {
    toggleSideMenu() {
      this.$store.commit('toggleMenu');
    }
  }
}
</script>

<style scoped>

.dashboardGrid {
  height: 100vh;
}

@media only screen and (min-width: 769px) {
  .dashboardGrid {
    display: grid;
    grid-template-columns: 260px 1fr;
    height: 100vh;
  }
}

.dashboardContent {
  background-color: #E8F3FE;
  overflow-y: scroll;
  padding: 30px 30px;
  height: 100vh;
  width: 100%;
}
</style>
