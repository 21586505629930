import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// fontawesome
import '@fortawesome/fontawesome-free/css/all.min.css'
import '@fortawesome/fontawesome-free/js/all.min'

// bulma
import 'bulma/css/bulma.min.css'

createApp(App).use(store).use(router).mount('#app')
