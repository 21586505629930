import {createStore} from "vuex";
import {global} from "@/store/stores/store.global";
import {companiesTable} from "@/store/stores/store.companiesTable";
import {resellers} from "@/store/stores/store.resellers";
import {goldylocksModules} from "@/store/stores/store.modules";
import {userNotifications} from "@/store/stores/store.userNotifications"

export default createStore({
    modules: {
        global,
        companiesTable,
        resellers,
        goldylocksModules,
        userNotifications,
    }
})
