<template>
  <div>
    <div class="box plan-card" :class="{'opacity':isDisabled}">
      <div class="is-flex is-justify-content-space-between">
        <div>
          <p class="has-text-grey" v-if="isExtension">Funcionalidade</p>
          <p class="has-text-grey" v-else>Plano</p>
          <p class="has-text-grey-dark has-text-weight-semibold is-size-4">{{ plan.nome_plano }}</p>
        </div>
        <div>
          <span class="is-size-7">Anuidade</span>
          <p class="is-size-2 has-text-weight-bold" style="margin-top:-19px;" :class="textColor">{{ anualValue }}<span
              class="is-size-7 has-text-grey">&euro;</span>
          </p>

        </div>
      </div>
      <div class="mt-2">
        {{ plan.designacao }}
      </div>
      <div class="top-border mt-5 plan-details" v-html="plan.designacao_html"></div>
      <div class="top-border mt-2">
        <!-- licensed information -->
        <div v-if="isEnabled" class="is-flex is-flex-wrap-wrap is-flex-direction-column is-align-content-space-around">
          <div class="mt-2 mb-2">
            <p class=""><span class="is-size-6 has-text-weight-semibold"
                              :class="{'has-text-primary': remainingDays > 15,'has-text-warning-dark': remainingDays <= 15 && remainingDays > 8,'has-text-danger':remainingDays <=8}">{{
                remainingDays
              }}</span>&nbsp;<span class="is-size-7 has-text-grey-ligh">dias restantes.</span></p>
            <progress class="progress is-small" :max="progressMax" :value="remainingDays"
                      :class="{'is-primary':remainingDays > 15,'is-warning':remainingDays <= 15 && remainingDays > 8,'is-danger':remainingDays <= 8}"></progress>
          </div>
          <div class="columns is-gapless">
            <div class="column">
              <button class="button is-fullwidth is-link is-light is-small" @click="purchaseMonth">
                Renovar&nbsp;<span class="has-text-weight-semibold">mensal</span>
              </button>
            </div>
            <div class="column">
              <button class="button is-fullwidth is-primary is-light is-small" @click="purchaseAnual">
                Renovar&nbsp;<span class="has-text-weight-semibold">anual</span>
              </button>
            </div>
          </div>
        </div>
        <!-- buy button -->
        <div class="is-flex is-flex-wrap-wrap is-justify-content-space-around" v-else>
          <button class="button is-fullwidth mt-2 is-primary" :disabled="isDisabled" @click="purchaseAnual">Subscrever
          </button>
          <a class="is-size-7 mt-3" @click="purchaseMonth">ou mensalmente por {{ mensalValue }}&euro;</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlanCard",
  props: ['plan', 'company'],
  emits: ['purchase'],
  methods: {
    purchaseAnual() {
      this.$emit('purchase', this.plan, 365);
    },
    purchaseMonth() {
      this.$emit('purchase', this.plan, 30);
    }
  },
  computed: {
    textColor() {
      if (this.plan.nome_plano === "Green") {
        return 'has-text-success';
      } else if (this.plan.nome_plano === "Blue") {
        return 'has-text-info';
      } else if (this.plan.nome_plano === "Orange") {
        return 'has-text-warning';
      } else {
        return 'has-text-grey';
      }
    },
    isEnabled() {
      if (this.company.modules) {
        return ((this.company.plan === this.plan.codigo_plano && this.remainingDays > 0) || (this.company.modules[this.plan.codigo_plano] && this.company.modules[this.plan.codigo_plano].active));
      }
    },
    isDisabled() {
      if (this.remainingDays > 0) {
        if (this.plan.codigo_plano === "green") {
          if (this.company.plan !== "green") {
            return true;
          }
        } else if (this.plan.codigo_plano === "blue") {
          if (this.company.plan === "orange") {
            return true;
          }
        }
      }


      return false;
    },
    isExtension() {
      return (this.plan.extensao === "1");
    },
    anualValue() {
      return parseInt(this.plan.valor_anual);
    },
    mensalValue() {
      return this.plan.valor_mensal;
    },
    remainingDays() {
      if (this.company.modules) {
        if (this.isExtension && this.company.modules[this.plan.codigo_plano]) {
          return parseInt(this.company.modules[this.plan.codigo_plano].remainingDays);
        } else if(this.company.modules['vendas']) {
          return parseInt(this.company.modules['vendas'].remainingDays);
        }
      } else {
        return 0;
      }
    },
    companyModuleInfo() {
      if (this.company.modules) {
        if (this.isExtension && this.company.modules[this.plan.codigo_plano]) {
          return this.company.modules[this.plan.codigo_plano];
        } else {
          return this.company.modules['vendas'];
        }
      }
    },
    progressMax() {
      if (this.remainingDays > 31) {
        return 365;
      } else {
        return 31;
      }
    }
  }
}
</script>

<style scoped>
.plan-card {
  height: 530px;
}

.plan-details {
  height: 260px;
}

.top-border {
  border-top: 1px solid #eaeaea;
}

.plan-banner {
  margin-left: -20px;
  margin-right: -20px;
}

.opacity {
  opacity: .4;
}
</style>
