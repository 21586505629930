<template>
  <p class="has-text-weight-bold is-size-4">Revendedor</p>
  <p class="is-size-6">Faça aqui a gestão dos seus clientes.</p>
  <CompaniesTable :companies="companies"></CompaniesTable>
</template>

<script>
import CompaniesTable from "@/components/tables/CompaniesTable";

export default {
  name: "ResellerCompanies",
  components: {CompaniesTable},
  computed: {
    companies() {
      return this.$store.getters.resellerCompanies;
    }
  },
  mounted() {
    this.$store.dispatch('fetchResellerCompanies');
    this.$store.commit('context','resellercompanies')
  }
}
</script>

<style scoped>

</style>
