<template>
  <p class="has-text-weight-bold is-size-4">Detalhes empresa</p>
  <div class="container">
    <div class="box">
      <!--        -->
      <div class="columns">
        <div class="is-flex is-justify-content-end is-hidden-tablet">
          <CompanyDetailsDropdown :company="company"></CompanyDetailsDropdown>
        </div>
        <div
            class="column right-border is-4 is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
          <div :style="logoStyle" class="appLogo"></div>
          <div class="mt-6 has-text-centered">
            <p class="has-text-grey-dark has-text-weight-semibold">{{ company.nome_goldylocks }}</p>
            <p class="has-text-grey-light is-size-7">Criado em {{ company.data_instalacao }}</p>
          </div>
          <div class="mt-3 top-border">
            <div class="mt-3">
              <span class="is-size-7 has-text-grey">Plano ativo </span><br>
              <span :class="textColor" class="is-uppercase has-text-weight-semibold is-size-5">{{ company.plan }}</span>
            </div>
            <div class="mt-2">
              <a :href="appURL" class="button is-primary is-light is-small has-text-weight-semibold"
                 target="_blank">Aceder</a>
            </div>
          </div>
        </div>
        <div class="column is-8">
          <!-- contacts -->
          <div>
            <div class="is-flex is-justify-content-end is-hidden-mobile">
              <CompanyDetailsDropdown :company="company"></CompanyDetailsDropdown>
            </div>
            <p class="has-text-grey-light has-text-weight-semibold mt-4">Dados empresa</p>
            <div class="columns mt-1">
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">NIF</p>
                <p class="mt-1 has-text-grey-light has-text-weight-semibold">{{ company.nif_goldylocks }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">Contabilista&nbsp;<a v-if="!editingAccountant"
                                                                                            class="is-size-7"
                                                                                            @click="editAccountant">alterar</a>
                </p>
                <p v-if="!editingAccountant" class="mt-1 has-text-grey-light has-text-weight-semibold">
                  {{ accountantEmail }}</p>
                <HiddenInput v-else :value="accountantEmail" type="email" @save="saveAccountant"></HiddenInput>
              </div>
              <div v-if="isAdministration" class="column">
                <div v-if="profile.type === '1'">
                  <p class="has-text-grey-dark has-text-weight-semibold">Agente&nbsp;<a v-if="!editingReseller"
                                                                                        class="is-size-7"
                                                                                        @click="editReseller">alterar</a>
                  </p>
                  <p v-if="!editingReseller" class="mt-1 has-text-grey-light has-text-weight-semibold">{{
                      resellerEmail
                    }}</p>
                  <HiddenInput v-else :value="resellerEmail" type="email" @save="saveReseller"></HiddenInput>
                </div>
              </div>
            </div>
          </div>
          <!-- contacts -->
          <div class="top-border mt-4">
            <p class="has-text-grey-light has-text-weight-semibold mt-4">Informação</p>
            <div class="columns mt-1">
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">Morada</p>
                <p class="mt-1 has-text-grey-light has-text-weight-semibold">{{ company.morada_goldylocks }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">Código Postal</p>
                <p class="mt-1 has-text-grey-light has-text-weight-semibold">{{ company.cp_goldylocks }}</p>
              </div>
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">Localidade</p>
                <p class="mt-1 has-text-grey-light has-text-weight-semibold">{{ company.localidade_goldylocks }}</p>
              </div>
            </div>
          </div>
          <!-- technical information -->
          <div v-if="isAdministration" class="top-border mt-4">
            <p class="has-text-grey-light has-text-weight-semibold mt-4"><span class="fa fa-wrench"></span> Informação
              técnica</p>
            <div class="columns mt-1">
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold"><span class="fa fa-code-branch"></span> Git
                  Branch</p>
                <div class="select is-dark is-small">
                  <select v-model="company.git_branch" @change="changeCompanyGitBranch">
                    <option value="alpha">Alpha</option>
                    <option value="beta">Beta</option>
                    <option value="master">Master</option>
                  </select>
                </div>
              </div>
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold"></p>
                <p class="mt-1 has-text-grey-light has-text-weight-semibold"></p>
              </div>
              <div class="column">
                <p class="has-text-grey-dark has-text-weight-semibold">Acesso técnico</p>
                <a :href="techURL" class="mt-1 has-text-grey-light has-text-weight-semibold" target="_blank">Abrir <span
                    class="fa fa-external-link-alt"></span></a>
              </div>
            </div>
          </div>
          <!-- module information -->
          <div v-if="isDataLoaded">
            <div v-if="isAdministration" class="top-border mt-4">
              <p class="has-text-grey-light has-text-weight-semibold mt-4"><span class="fa fa-calendar-alt"></span> Controlo licenciamento</p>
              <ModulesControlTable :company="company"></ModulesControlTable>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <PlansTable :company="company"></PlansTable>
  </div>
</template>

<script>
import PlansTable from "@/components/tables/PlansTable";
import HiddenInput from "@/components/inputs/hiddenInput";
import CompanyDetailsDropdown from "@/components/menus/CompanyDetailsDropdown";
import ModulesControlTable from "@/components/tables/ModulesControlTable";

export default {
  name: "CompanyDetails",
  components: {
    ModulesControlTable,
    CompanyDetailsDropdown,
    HiddenInput,
    PlansTable
  },
  data() {
    return {
      isDataLoaded: false,
      editingAccountant: false,
      editingReseller: false
    }
  },
  methods: {
    editAccountant() {
      this.editingAccountant = !this.editingAccountant;
    },
    editReseller() {
      this.editingReseller = !this.editingReseller;
    },
    saveAccountant(newAccountantEmail) {
      this.$store.dispatch('updateCompanyAccountant', newAccountantEmail)
          .then(() => {
            this.$store.dispatch('fetchCompanyDetails', this.$store.getters.companyDetails['id_goldylocks']);
            this.editAccountant();
          })
    },
    saveReseller(newResellerEmail) {
      this.$store.dispatch('updateCompanyReseller', newResellerEmail)
          .then(() => {
            this.$store.dispatch('fetchCompanyDetails', this.$store.getters.companyDetails['id_goldylocks']);
            this.editReseller();
          })
    },
    changeCompanyGitBranch() {
      this.$store.dispatch('changeCompanyGitBranch', {
        companyId: this.$store.getters.companyDetails['id_goldylocks'],
        newGitBranch: this.company.git_branch
      })
    },
  },
  computed: {
    isAdministration() {
      return (this.$store.getters.profile.type === "1");
    },
    profile() {
      return this.$store.getters.profile;
    },
    logoStyle() {
      if (this.company.alias_goldylocks) {
        return `background:url(https://app.goldylocks.pt/${this.company.alias_goldylocks}/api/logotipo);`
      }
    },
    company() {
      return this.$store.getters.companyDetails;
    },
    textColor() {
      if (this.company.plan === "green") {
        return 'has-text-success';
      } else if (this.company.plan === "blue") {
        return 'has-text-info';
      } else if (this.company.plan === "orange") {
        return 'has-text-warning';
      } else {
        return 'has-text-grey';
      }
    },
    phone() {
      return this.company.telefone_cliente ? this.company.telefone_cliente : '--';
    },
    mobile() {
      return this.company.telemovel_cliente ? this.company.telemovel_cliente : '--';
    },
    email() {
      return this.company.email_empresa ? this.company.email_empresa : '--';
    },
    accountantEmail() {
      if (this.company.contabilista_email) {
        return this.company.contabilista_email;
      } else {
        return '--';
      }
    },
    resellerEmail() {
      if (this.company.agente_email) {
        return this.company.agente_email;
      } else {
        return '--';
      }
    },
    appURL() {
      return `https://app.goldylocks.pt/${this.company['alias_goldylocks']}`
    },
    techURL() {
      return `https://app.goldylocks.pt/${this.company['alias_goldylocks']}/?hash=${this.company['url_hash']}`;
    }
  },
  mounted() {
    this.$store.dispatch('fetchCompanyDetails', this.$route.params.id)
    .then(() => {
      this.isDataLoaded = true;
    })
    this.$store.dispatch('fetchResellers')
  }
}
</script>

<style scoped>
.top-border {
  border-top: 1px solid #eaeaea;
}

@media screen and (min-width: 768px) {
  .right-border {
    border-right: 1px solid #eaeaea;
  }
}

.appLogo {
  width: 128px;
  height: 128px;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 64px;
  border: 5px solid #EBEBEB;
}
</style>
