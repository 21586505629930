<template>
  <p class="has-text-grey-light is-size-7">Plano</p>
  <p>
    <span class="plan-tag is-uppercase has-text-weight-semibold" :class="PlanColor">{{ company.plan }}</span>
  </p>
</template>

<script>
export default {
  name: "Plan",
  props: ['company'],
  computed: {
    PlanColor() {
      return this.company.plan;
    }
  }
}
</script>

<style scoped>
.plan-tag {
  border-radius: 5px;
}

.plan-tag.green {
  color: #27ae60;
}

.plan-tag.blue {
  color: #3498db;
}

.plan-tag.orange {
  color: #f39c12;
}
</style>
