export const resellers = {
    state: {
        resellers: []
    },
    mutations: {
        resellers: (state: any, resellers: any) => state.resellers = resellers
    },
    getters: {
        resellers: (state: any) => state.resellers
    },
    actions: {
        createNewCompany(context: any, newCompanyData: any) {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/resellercompanies`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(newCompanyData),
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                    .then(() => {
                        resolve()
                    })
                    .catch(err => {
                        console.error(err)
                        reject()
                    })
            })
        },
        fetchResellers: (context: any) => {
            return new Promise<void>((resolve) => {
                fetch(`/api/resellers`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                })
                    .then(res => res.json())
                    .then(resellers => {
                        context.commit('resellers', resellers)
                        resolve()
                    })
            })
        }
    }
}
