export const userNotifications = {
    state: {
        unreadNotifications: []
    },
    mutations: {
        unreadNotifications: (state: any, unreadNotifications: any) => state.unreadNotifications = unreadNotifications,
    },
    getters: {
        unreadNotifications: (state: any) => state.unreadNotifications,
        unreadNotificationsCount: (state: any) => state.unreadNotifications.length,
    },
    actions: {
        fetchUnreadNotifications: (context: any) => {
            fetch(`/api/usernotifications`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            }).then(res => res.json())
                .then((res) => context.commit('unreadNotifications', res))
                .catch(() => {
                    context.commit('unreadNotifications', [])
                })
        },
        markAsRead: (context: any, notificationHash: string) => {
            return new Promise<void>(((resolve, reject) => {
                fetch(`/api/usernotifications`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        notification_hash: notificationHash
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + localStorage.getItem('token')
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve()
                    } else {
                        reject()
                    }
                })
            }));
        }
    }
}
