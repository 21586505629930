import {createRouter, createWebHistory} from 'vue-router'
import Login from '../views/Login.vue'
import RecoverPassword from '../views/RecoverPassword.vue'
import Dashboard from '../views/Dashboard.vue'
import Start from "@/components/dashboard/Start.vue";
import ResellerCompanies from "@/components/reseller/ResellerCompanies.vue";
import CompanyDetails from "@/components/dashboard/CompanyDetails.vue";
import Profile from "@/components/dashboard/Profile.vue";
import Companies from "@/components/dashboard/Companies.vue";
import AccountantsCompanies from "@/components/accountants/AccountantsCompanies.vue";
import UserRegistration from "@/views/UserRegistration.vue";
import RegistrationSuccess from "@/views/RegistrationSuccess.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import Activation from "@/views/Activation.vue";
import CreateCompanyForm from "@/components/companies/CreateCompanyForm.vue";

const routes = [
    {
        path: '',
        redirect: '/dashboard/'
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '',
                name: 'Dashboard',
                components: {
                    dashboardContent: Start
                }
            },
            {
                path: '/companies/create',
                name: 'CreateCompanyForm',
                components: {
                    dashboardContent: CreateCompanyForm
                }
            },
            {
                path: '/companies',
                name: 'Companies',
                components: {
                    dashboardContent: Companies
                }
            },
            {
                path: '/resellercompanies',
                name: 'ResellerCompanies',
                components: {
                    dashboardContent: ResellerCompanies
                }
            },
            {
                path: '/accountantcompanies',
                name: 'AccountantCompanies',
                components: {
                    dashboardContent: AccountantsCompanies
                }
            },
            {
                path: '/companies/:id',
                name: 'CompanyDetails',
                components: {
                    dashboardContent: CompanyDetails
                }
            },
            {
                path: '/profile',
                name: 'Profile',
                components: {
                    dashboardContent: Profile
                }
            }
        ]
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    },
    {
        path: '/recoverpassword',
        name: 'RecoverPassword',
        component: RecoverPassword
    },
    {
        path: '/recoverpassword/:hash',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/registration',
        name: 'UserRegistration',
        component: UserRegistration
    },
    {
        path: '/registrationsuccess',
        name: 'RegistrationSuccess',
        component: RegistrationSuccess
    },
    {
        path: '/activation/:hash',
        name: 'Activation',
        component: Activation
    },

    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: function () {
        return import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
      }
    }*/
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!localStorage.getItem('token')) {
            next({
                path: '/login',
                query: {redirect: to.fullPath}
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
