export const companiesTable = {
    state: {
        search: {
            search: "",
            validity: "",
            sort: "name-asc"
        }
    },
    mutations: {
        search: (state: any, search: string) => state.search.search = search,
        validity: (state: any, validity: string) => state.search.validity = validity,
        sort: (state: any, sort: string) => state.search.sort = sort,
    },
    getters: {
        search: (state: any) => state.search.search,
        validity: (state: any) => state.search.validity,
        sort: (state: any) => state.search.sort,
    },
    actions: {
        clearSearchFilter(context: any) {
            context.commit('search', '')
            context.commit('validity', '')
            context.commit('sort', 'name-asc')
        }
    }
}
