<template>
  <section class="section">
    <div class="container">


      <div class="columns is-multiline is-mobile mb-5">
        <div class="column is-12-mobile">
          <div class="field">
            <div class="control has-icons-left has-icons-right">
              <input class="input is-small" type="email" placeholder="Pesquisar empresas" v-model="search">
              <span class="icon is-small is-left">
            <i class="fas fa-search fa-xs"></i>
          </span>
            </div>
          </div>
        </div>
        <div class="column is-6-mobile">
          <div class="select is-small">
            <select v-model="validity">
              <option value="">Todas</option>
              <option value="active">Ativas</option>
              <option value="expiring">A expirar</option>
              <option value="expired">Expiradas</option>
            </select>
          </div>
        </div>
        <div class="column is-6-mobile">
          <div class="select is-small">
            <select v-model="sort">
              <option value="remaining-days-asc">Dias restantes</option>
              <option value="name-asc">Nome A-Z</option>
            </select>
          </div>
        </div>
      </div>

      <div class="columns header-companies is-hidden-touch">
        <div class="column is-2 has-text-centered">Estado</div>
        <div class="column is-5">Empresa</div>
        <div class="column is-2"></div>
        <div class="column is-1"></div>
        <div class="column is-2"></div>
      </div>
      <div>
        <CompaniesRow :company="company" v-for="company of searchResults" :key="company.id_goldylocks"></CompaniesRow>
      </div>
    </div>
  </section>
</template>

<script>
import CompaniesRow from "@/components/dashboard/CompaniesRow";

export default {
  name: "CompaniesTable",
  components: {CompaniesRow},
  props: ['companies'],
  computed: {
    search: {
      get() {
        return this.$store.getters.search
      },
      set(val) {
        this.$store.commit('search', val)
      }
    },
    validity: {
      get() {
        return this.$store.getters.validity
      },
      set(val) {
        this.$store.commit('validity', val)
      }
    },
    sort: {
      get() {
        return this.$store.getters.sort
      },
      set(val) {
        this.$store.commit('sort', val)
      }
    },
    searchResults() {
      let filteredCompanies = this.companies;

      // text search
      if (this.search.length > 0) {
        let searchString = this.search.toLowerCase();
        filteredCompanies = filteredCompanies.filter(company => {
          return (
              company.nome_goldylocks.toLowerCase().includes(searchString)
              || company.nif_goldylocks.toLowerCase().includes(searchString)
              || company.alias_goldylocks.toLowerCase().includes(searchString)
              || (company.agente_email ? company.agente_email : '').toLowerCase().includes(searchString)
              || (company.agente_nome ? company.agente_nome : '').toLowerCase().includes(searchString)
              || (company.contabilista_email ? company.contabilista_email : '').toLowerCase().includes(searchString)
              || (company.contabilista_nome ? company.contabilista_nome : '').toLowerCase().includes(searchString)
          );
        })
      }

      // validity filter
      if (this.validity === "active") {
        filteredCompanies = filteredCompanies.filter(company => {
          const remainingDays = parseInt(company.modules.vendas.remainingDays);
          return (remainingDays > 15);
        })
      } else if (this.validity === "expiring") {
        filteredCompanies = filteredCompanies.filter(company => {
          const remainingDays = parseInt(company.modules.vendas.remainingDays);
          return (remainingDays < 15 && remainingDays > 0);
        })
      } else if (this.validity === "expired") {
        filteredCompanies = filteredCompanies.filter(company => {
          const remainingDays = parseInt(company.modules.vendas.remainingDays);
          return (remainingDays <= 0);
        })
      }


      // order
      if (this.sort === "name-desc") {
        // by name
      } else if (this.sort === "remaining-days-asc") {
        // by remaining days
        let orderedArray = [];

        // order each filtered company
        for (let c of filteredCompanies) {

          // varable to check if the company is already at the ordered array
          let isPlaced = false;

          // company to order remaining days
          const remainingDays = parseInt(c.modules.vendas.remainingDays);

          // check if array is empty
          if (orderedArray.length > 0) {

            // search each array index for value equal or bigger
            for (let i in orderedArray) {
              const indexRemainingDays = parseInt(orderedArray[i].modules.vendas.remainingDays);

              if (!isPlaced && indexRemainingDays >= remainingDays) {
                orderedArray.splice(i, 0, c);
                isPlaced = true;
              }
            }

            // if company has not been inserted, its inserted at the last position
            if (!isPlaced) {
              orderedArray.push(c);
            }

          } else {
            // if array is empty, insert at the first position
            orderedArray.push(c);
          }
        }

        // apply ordered array to filtered
        filteredCompanies = orderedArray;
      }

      return filteredCompanies;
    }
  },
  mounted() {
    this.$store.commit('context', 'usercompanies')
    this.$store.dispatch('fetchProfile')
  }
}
</script>

<style scoped>
.header-companies {
  background-color: #F5F6FA;
  color: #8D90A1;
  font-weight: bold;
  border-radius: 10px;
}

.header-companies > .columns > .column {
  /*padding-left: 50px;*/
}
</style>
