import {parseCompanyInformation} from "@/libs/CompaniesUtils";

/**
 * change git branch interface
 */
interface changeGitBranchOptions {
    companyId: number;
    newGitBranch: string;
}

/**
 * reset password interface
 */
interface setNewPasswordFromRecovery {
    recoverHash: string,
    newPassword: string
}

interface newCompayLicenseData {
    companyID: number;
    name: string;
    description: string;
    address: string;
    zipcode: string;
    city: string;
    vat?: string;
}

export const global = {
    state: {
        profile: {},
        context: 'usercompanies',
        currentPage: null,
        sideMenuOpen: false,
        userData: {},
        token: null,
        companyDetails: {},
        companies: [],
        accountantCompanies: [],
        resellerCompanies: [],
        plans: [],
        stripe: {
            public_key: "pk_live_51HTpoDBvpySkNODwjYfAwJVwkTjjGCxoSzpGsxFjxonxWiKayrb18SaBiuWSgFM8YraNMT9jCCtvSp42a4LmrRUt00zg3q96ug"
        },
        currentPayment: {
            hash: ""
        }
    },
    mutations: {
        context(state: any, context: string) {
            state.context = context
        },
        profile(state: any, profile: any) {
            state.profile = profile;
        },
        toggleMenu(state: any) {
            state.sideMenuOpen = !state.sideMenuOpen;
        },
        currentPage(state: any, page: any) {
            state.currentPage = page;
        },
        userData(state: any, userData: any) {
            state.userData = userData;
        },
        token(state: any, token: any) {
            state.token = token;
        },
        companies(state: any, companies: any) {
            state.companies = companies.map((company: any) => {
                return parseCompanyInformation(company)
            });
        },
        accountantCompanies(state: any, companies: any) {
            state.accountantCompanies = companies.map((company: any) => {
                return parseCompanyInformation(company)
            });
        },
        resellerCompanies(state: any, companies: any) {
            state.resellerCompanies = companies.map((company: any) => {
                return parseCompanyInformation(company)
            });
        },
        companyDetails(state: any, companyDetails: any) {
            state.companyDetails = parseCompanyInformation(companyDetails)
        },
        companyUserEmail(state: any, newUserEmail: string) {
            state.companyDetails.email_utilizador = newUserEmail
        },
        plans(state: any, plans: any) {
            state.plans = plans;
        },
        paymentHash(state: any, paymentHash: any) {
            state.currentPayment.hash = paymentHash;
        },
    },
    getters: {
        context: (state: any) => state.context,
        profile: (state: any) => state.profile,
        token: (state: any) => state.token,
        userData: (state: any) => state.userData,
        companies: (state: any) => state.companies,
        sideMenuOpen: (state: any) => state.sideMenuOpen,
        currentPage: (state: any) => state.currentPage,
        resellerCompanies: (state: any) => state.resellerCompanies,
        accountantCompanies: (state: any) => state.accountantCompanies,
        companyDetails: (state: any) => state.companyDetails,
        plans: (state: any) => state.plans,
        mainPlans: (state: any) => state.plans.filter(({extensao}: any) => extensao === "0"),
        extensions: (state: any) => state.plans.filter(({extensao}: any) => extensao === "1"),
        paymentHash: (state: any) => state.currentPayment.hash,
        stripePublicKey: (state: any) => state.stripe.public_key,
    },
    actions: {
        clearData(context: any) {
            context.commit('profile', {});
            context.commit('companies', []);
            context.commit('resellerCompanies', []);
            context.commit('accountantCompanies', []);
        },
        saveProfile(context: any) {
            return new Promise((resolve, reject) => {
                let profile = context.getters.profile;
                fetch(`/api/profile`, {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        "nome_cliente": profile.name,
                        "morada_cliente": profile.address,
                        "cp_cliente": profile.zipcode,
                        "localidade_cliente": profile.city,
                        "email_cliente": profile.email,
                        "telefone_cliente": profile.phone,
                        "telemovel_cliente": profile.mobile
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                });
            })
        },
        updateCompanyAccountant(context: any, accountantEmail: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/usercompanies/accountant/${context.getters.companyDetails.id_goldylocks}`, {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        'email': accountantEmail
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
            })
        },
        updateCompanyReseller(context: any, resellerEmail: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/usercompanies/reseller/${context.getters.companyDetails.id_goldylocks}`, {
                    method: 'PUT',
                    mode: 'cors',
                    body: JSON.stringify({
                        'email': resellerEmail
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
            })
        },
        changePassword(context: any, {oldPassword, newPassword}: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/profile/password`, {
                    method: 'PUT',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    },
                    body: JSON.stringify({
                        "old_password": oldPassword,
                        "new_password": newPassword
                    })
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
            });
        },
        fetchProfile(context: any) {
            fetch(`/api/profile`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => res.json())
                .then(profile => context.commit('profile', profile))
                .catch(res => {
                    console.error(res)
                    window.location.assign('/login')
                })
        },
        getUserData(context: any) {
            context.commit('userData', JSON.parse(<string>localStorage.getItem('userData')));
            context.commit('token', localStorage.getItem('token'));
        },
        fetchCompanies(context: any) {
            fetch(`/api/usercompanies`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => res.json()).then(companies => context.commit('companies', companies));
        },
        fetchCompanyDetails(context: any, companyId: number) {
            return new Promise((resolve, reject) => {
                fetch(`/api/${context.getters.context}/${companyId}`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => res.json())
                    .then(companyDetails => {
                        context.commit('companyDetails', companyDetails)
                        resolve(companyDetails);
                    });
            });
        },
        fetchResellerCompanies(context: any) {
            fetch(`/api/resellercompanies`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => res.json()).then(resellerCompanies => context.commit('resellerCompanies', resellerCompanies));
        },
        fetchAccountantCompanies(context: any) {
            fetch(`/api/accountantscompanies`, {
                method: 'GET',
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => res.json()).then(accountantCompanies => context.commit('accountantCompanies', accountantCompanies));
        },
        fetchPlans(context: any) {
            return new Promise(((resolve, reject) => {
                fetch(`/api/plans`, {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                })
                    .then(res => res.json())
                    .then(plans => {
                        context.commit('plans', plans)
                        resolve(plans)
                    });
            }))
        },
        requestPaymentHash(context: any, data: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/activations`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => res.json()).then(paymentHashData => {
                    context.commit('paymentHash', paymentHashData['payment_hash'])
                    resolve(paymentHashData['payment_hash'])
                }).catch(() => reject())
            });
        },
        requestMbWayPayment(context: any, data: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/payments/mbway`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res);
                    } else {
                        reject(res);
                    }
                })
            });
        },
        requestMultibancoPayment(context: any, data: any) {
            return new Promise((resolve, reject) => {
                fetch(`/api/payments/multibanco`, {
                    method: 'POST',
                    mode: 'cors',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve(res.json());
                    } else {
                        reject(res);
                    }
                })
            });
        },
        requestStripePayment(context: any, data: any) {
            return new Promise((resolve) => {
                fetch(`/api/payments/stripe`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => res.json()).then(res => resolve(res))
            })
        },
        async deleteCompany(context: any, companyId: number) {
            await fetch(`/api/companies/${companyId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => (res.status === 200))
        },
        async requestCompanyPasswordRecovery(context: any, companyId: number) {
            await fetch(`/api/companies/recoverpassword/${companyId}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + context.state.token
                }
            }).then(res => (res.status === 200))
        },
        changeCompanyGitBranch: (context: any, data: changeGitBranchOptions) => {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/companies/updategitbranch/${data.companyId}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        new_git_branch: data.newGitBranch
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    (res.status === 200) ? resolve() : reject();
                })
            })
        },
        updateCompanyLicenseData(state: any, data: newCompayLicenseData) {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/companies/${data.companyID}/`, {
                    method: 'PUT',
                    headers: {
                        Authorization: `Bearer ${state.state.token}`,
                    },
                    body: JSON.stringify({
                        nome_goldylocks: data.name,
                        morada_goldylocks: data.address,
                        cp_goldylocks: data.zipcode,
                        localidade_goldylocks: data.city,
                        designacao_goldylocks: data.description,
                    }),
                }).then(res => {
                    (res.status === 200) ? resolve() : reject()
                })
            })
        },
        /**
         * change compnay user using email
         * @param context
         * @param data
         */
        changeCompanyUserByEmail: (context: any, data: any) => {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/companies/updateuseremail/${data.company_id}`, {
                    method: 'PUT',
                    body: JSON.stringify({
                        user_email: data.user_email
                    }),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    (res.status === 200) ? resolve() : reject();
                })
            })
        },
        requestModuleActivation: (context: any, data: any) => {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/activations/module`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    (res.status === 200) ? resolve() : reject();
                })
            })
        },
        setNewPasswordFromRecovery(context: any, data: setNewPasswordFromRecovery) {
            return new Promise<void>((resolve, reject) => {
                fetch(`/api/users/recoverpassword`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + context.state.token
                    }
                }).then(res => {
                    if (res.status === 200) {
                        resolve()
                    } else {
                        reject()
                    }
                })
            })
        },
    }
}
