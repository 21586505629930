<template>
  <div class="columns companies-row mt-1">
    <div class="column is-2 has-text-centered" :class="{'opacity':!(licenseDaysRemaining > 0)}">
      <p><span class="tag is-primary is-light" v-if="active">Activo</span>
        <span class="tag is-danger is-light" v-else>Expirado</span></p></div>
    <div class="column is-5" :class="{'opacity':!(licenseDaysRemaining > 0)}">
      <p class="is-size-7 company-details-subtitle">{{ company.nif_goldylocks }}</p>
      <p class="has-text-weight-semibold">{{ company.nome_goldylocks }}</p>
      <p class="is-size-7"><a :href="url" target="_blank">/{{ company.alias_goldylocks }}</a></p>

      <!-- Administration details -->
      <div v-if="isAdministration" class="is-size-7 mt-2 columns">
        <div class="column">
          <p class="has-text-grey-darker">Utilizador</p>
          <p class="has-text-grey">{{ company.email_utilizador }}</p>
        </div>
        <div class="column" v-if="company.agente_email">
          <p class="has-text-grey-darker">Agente</p>
          <p class="has-text-grey">{{ company.agente_email }}</p>
        </div>
        <div class="column" v-if="company.contabilista_email">
          <p class="has-text-grey-darker">Contabilista</p>
          <p class="has-text-grey">{{ company.contabilista_email }}</p>
        </div>
      </div>

    </div>
    <div class="column is-3" :class="{'opacity':!(licenseDaysRemaining > 0)}">
      <div class="columns is-mobile">
        <div class="column">
          <p class="has-text-grey">
            <Plan :company="company" class="mt-3"></Plan>
          </p>

        </div>
        <div class="column">
          <!-- active license -->
          <p class="is-size-6 has-text-weight-semibold"
             :class="{'has-text-primary': licenseDaysRemaining > 15,'has-text-warning-dark':licenseDaysRemaining <= 15 && licenseDaysRemaining > 8,'has-text-danger':licenseDaysRemaining <= 8}">
            {{ licenseDaysRemaining }}</p>
          <p class="is-size-7 has-text-grey-light" v-if="licenseDaysRemaining">dias restantes</p>
          <progress class="mt-2 progress is-small"
                    :class="{'is-primary':licenseDaysRemaining > 15,'is-warning':licenseDaysRemaining <= 15 && licenseDaysRemaining > 8,'is-danger':licenseDaysRemaining <= 8}"
                    :value="licenseDaysRemaining" v-if="licenseDaysRemaining" :max="progressMax"></progress>
          <div v-else>
            <!-- no license -->
            <p class="is-size-6 has-text-weight-semibold has-text-danger">0</p>
            <p class="is-size-7 has-text-grey-light">dias restantes</p>
          </div>
        </div>
      </div>
    </div>
    <div class="column is-2 is-12-mobile" v-if="licenseDaysRemaining > 0">
      <router-link :to="detailsLink" class="button is-fullwidth is-small mt-4">Detalhes</router-link>
      <a v-if="hasAdminAccess" target="_blank" :href="accountantURL" class="button is-fullwidth is-small is-info is-light mt-2" href="">Acesso&nbsp;<strong>TOC</strong></a>
    </div>
    <div class="column is-2 is-12-mobile" v-else>
      <router-link :to="detailsLink" class="button is-warning has-text-weight-bold is-fullwidth is-small mt-4">Reativar</router-link>
      <a v-if="hasAdminAccess" target="_blank" :href="accountantURL" class="button is-fullwidth is-small is-info is-light mt-2" href="">Acesso&nbsp;<strong>TOC</strong></a>
    </div>
  </div>
</template>

<script>
import Plan from "@/components/labels/Plan";

export default {
  name: "CompaniesRow",
  components: {Plan},
  props: ['company'],
  computed: {
    isAdministration() {
      return (this.$store.getters.profile.type === "1");
    },
    detailsLink() {
      return `/companies/${this.company.id_goldylocks}`
    },
    url() {
      return `https://app.goldylocks.pt/${this.company.alias_goldylocks}`
    },
    hasAdminAccess() {
      return (typeof this.company.url_hash !== "undefined")
    },
    accountantURL() {
      return `https://app.goldylocks.pt/${this.company.alias_goldylocks}/?hash=${this.company.url_hash}`
    },
    active() {
      return this.company.active;
    },
    progressMax() {
      if (this.licenseDaysRemaining > 30) {
        return 365;
      } else {
        return 30;
      }
    },
    licenseDaysRemaining() {
      if (this.company.modules.vendas) {
        return parseInt(this.company.modules.vendas.remainingDays);
      } else {
        return "";
      }
    }
  }
}
</script>

<style scoped>
.companies-row {
  background-color: #FFFFFF;
  color: #262E41;
  border-radius: 10px;
}

.company-details {
  background-color: #F6F7F9;
  color: #4F5259;
}

.company-details-subtitle {
  color: #8E909F;
}

.opacity {
  opacity: .3;
}

.no-opacity {
  opacity: 1;
}
</style>
