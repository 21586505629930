/**
 * parse company data into a structured object
 * @param companyData
 */
const parseCompanyInformation = (companyData: any) => {

    let plan: string = '';
    let status: any;
    let modules: any = {};

    if (companyData.status) {
        // parse status information
        status = JSON.parse(companyData.status);

        // parse modules information
        if (status.modulos) {

            // get company modules
            modules = parseCompanyModules(status.modulos);

            // get company plan from active modules
            plan = getCompanyPlanFromModules(modules)
        }

    }

    // deconstruct and parse status string to object
    return {
        ...companyData,
        info: status,
        modules,
        active: isCompanyActiveFromModules(modules),
        plan
    }
}

/**
 * parse company modules from status field in the database
 * @param companyModules
 */
const parseCompanyModules = (companyModules: any) => {

    let modules: any = [];

    // parse modules information
    companyModules.map((modulo: any) => {
        let moduleValidityUTC = Date.parse(modulo.validade);
        let nowUTC = Date.now();

        modules[modulo.modulo] = {
            module: modulo.modulo,
            expireDate: modulo.validade,
            moduleValidityUTC,
            active: (moduleValidityUTC >= nowUTC),
            remainingDays: ((moduleValidityUTC - nowUTC) / 86400000),
        }
    });

    return modules
}

/**
 * get company plan from active modules
 * @param companyModules
 */
const getCompanyPlanFromModules = (companyModules: any) => {

    let plan: string = '';

    if (companyModules['vendas'] && companyModules['cc'] && companyModules['compras']) {
        plan = 'orange';
    } else if (companyModules['vendas'] && companyModules['cc']) {
        plan = 'blue';
    } else if (companyModules['vendas']) {
        plan = 'green';
    }

    return plan;
}

/**
 * check if the company is active checking the sales module
 * @param companyModules
 */
const isCompanyActiveFromModules = (companyModules: any) => {
    return (companyModules.vendas && companyModules['vendas'].active)
}

export {
    parseCompanyInformation,
    parseCompanyModules,
    getCompanyPlanFromModules,
    isCompanyActiveFromModules
}
