<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
      <router-link class="navbar-item" to="/">
        <img src="https://i.imgur.com/Cb4nqcG.png" width="112" height="28"></router-link>

      <a @click="toggleMenu" role="button" class="navbar-burger" :class="{'is-active': menuOpen}" aria-label="menu"
         aria-expanded="false" data-target="navbarBasicExample">
        <span aria-hidden="true"></span> <span aria-hidden="true"></span> <span aria-hidden="true"></span> </a>
    </div>

    <!-- NAVBAR MENU -->
    <div id="navbarMenu" class="navbar-menu" :class="{'is-active': menuOpen}">
      <div class="navbar-end">
        <div class="navbar-item is-hidden-tablet-only is-hidden-desktop">
          <!-- LINK -->
          <a class="navbar-item"> Home </a>

          <!-- LINK -->
          <a class="navbar-item"></a>

          <!-- DROPDOWN -->
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link"> More </a>

            <div class="navbar-dropdown">
              <a class="navbar-item"> About </a> <a class="navbar-item"> Jobs </a> <a class="navbar-item"> Contact </a>
              <hr class="navbar-divider">
              <a class="navbar-item"> Report an issue </a>
            </div>
          </div>
        </div>

        <div class="navbar-item">
          {{userData.email}}
        </div>

        <!-- LOGOUT -->
        <div class="navbar-item">
          <div class="buttons">
            <a href="#" @click="logout" class="button is-danger">
              <strong>Sair</strong>&nbsp;<span class="fas fa-power-off"></span></a>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
  data() {
    return {
      menuOpen: false
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    logout() {
      localStorage.clear();
      this.$router.push('/login');
    }
  },
  computed: {
    token(){
      return this.$store.state.token;
    },
    userData() {
      return this.$store.getters.userData;
    }
  },
  mounted() {
    this.$store.dispatch('getUserData');
  }
}
</script>

<style scoped>

</style>
