import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("progress", {
      class: _normalizeClass(["progress is-small", _ctx.progressClass]),
      value: _ctx.progressValue,
      max: "100"
    }, null, 10, _hoisted_1)
  ]))
}