<template>
  <div class="dropdown is-right" :class="{'is-active': isOpen}">
    <div class="dropdown-trigger">
      <a aria-haspopup="true" aria-controls="dropdown-menu" @click="toggle">
        <span class="icon is-small">
          <span class="fas fa-cog" aria-hidden="true"></span>
        </span>
      </a>
    </div>
    <div class="dropdown-menu" id="dropdown-menu" role="menu">
      <div class="dropdown-content">
        <a class="dropdown-item" @click="requestPasswordRecovery">
          Recuperar acesso
        </a>
        <hr class="dropdown-divider">
        <a @click="removeCompany"
           class="dropdown-item has-text-weight-semibold has-background-danger-light has-text-danger">
          <span class="fas fa-exclamation-triangle"></span>&nbsp;Remover Goldylocks
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from "vue";
import Swal from "sweetalert2";

export default defineComponent({
  name: "CompanyDetailsDropdown",
  props: ['company'],
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    removeCompany() {
      this.toggle();
      const companyId = this.company['id_goldylocks'];
      //const warningConfirmation = confirm('Tem a certeza que deseja eliminar este Goldylocks?')

      Swal.fire({
        icon: `warning`,
        html: `Tem a certeza que deseja eliminar este Goldylocks?<br><p class="is-size-6">Esta operação é <strong>irreversivel</strong>!</p>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Remover`,
        confirmButtonColor: `#f14668`,

        cancelButtonText: `Cancelar`
      }).then(result => {
        if (result.isConfirmed) {
          if (this.$store.dispatch('deleteCompany', companyId)) {
            Swal.fire({
              text: `Este Goldylocks foi marcado para remoção e será eliminado logo que possivel!`,
              icon: 'success',
            });
          }
        }
      });
    },
    requestPasswordRecovery() {
      this.toggle();
      const companyId = this.company['id_goldylocks'];

      Swal.fire({
        icon: `warning`,
        text: `Deseja recuperar a senha de administrador deste Goldylocks?`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Sim`,
        confirmButtonColor: `#00d1b2`,
        cancelButtonText: `Não`
      }).then(result => {
        if (result.isConfirmed) {
          if (this.$store.dispatch('requestCompanyPasswordRecovery', companyId)) {
            Swal.fire({
              text: `Foi enviado um email para a sua caixa de correio com a nova senha.`,
              icon: 'success',
            });
          }
        }
      });
    }
  }
})
</script>

<style scoped>

</style>
