<template>
  <div>
    <p class="title">Bem-vindo</p><p class="subtitle ml-2">{{ userData.name }}</p>
  </div>
  <div class="container mt-6">
    <div class="columns is-multiline">
      <div class="column is-12-desktop">
        <UserNotificationsTable></UserNotificationsTable>
      </div>
    </div>
  </div>
</template>

<script>
import UserNotificationsTable from "@/components/dashboard/UserNotificationsTable";

export default {
  name: "Start",
  components: {UserNotificationsTable},
  computed: {
    userData() {
      return this.$store.getters.userData;
    }
  }
}
</script>

<style scoped>

</style>
