
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import {mapActions, mapGetters} from "vuex";
import companyDetails from "@/components/dashboard/CompanyDetails.vue";

export default defineComponent({
  name: "ModulesControlTable",
  props: ['company'],
  data() {
    return {
      selectedModule: 'all_active',
      selectedPeriod: 31,
      activationStartDate: 'modulo',
      isEmailValid: true,
      licenseData: {
        name: '',
        address: '',
        zipcode: '',
        city: '',
        description: '',
      }
    }
  },
  computed: {
    ...mapGetters(['companyDetails']),
    modules(): any {
      return (this.$store.getters.modules) || []
    },
    userEmail: {
      get(): string {
        return this.company.email_utilizador
      },
      set(newEmail: string) {
        this.$store.commit('companyUserEmail', newEmail)
      }
    }
  },
  methods: {
    ...mapActions(['updateCompanyLicenseData']),
    async changeCompanyLicenseData() {
      Swal.fire({
        icon: `warning`,
        html: `Tem a certeza que deseja alterar este Goldylocks?<br><p class="is-size-6">Esta operação é <strong>irreversivel</strong>!</p>`,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: `Alterar`,
        confirmButtonColor: `#007aa8`,

        cancelButtonText: `Cancelar`
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await this.updateCompanyLicenseData({
              name: this.licenseData.name,
              address: this.licenseData.address,
              zipcode: this.licenseData.zipcode,
              city: this.licenseData.city,
              companyID: this.companyDetails.id_goldylocks,
              description: this.licenseData.description,
            })

            Swal.fire({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Pedido efetuado de alteração de dados enviado.'
            } as any)
          } catch (e) {
            alert(e)
          }
        }
      });

    },
    changeCompanyUserByEmail() {
      this.$store.dispatch('changeCompanyUserByEmail', {
        company_id: this.$store.getters.companyDetails.id_goldylocks,
        user_email: this.userEmail
      }).then(() => {
        this.isEmailValid = true;
      }).catch(() => {
        this.isEmailValid = false;
      })
    },
    requestModuleActivation() {
      return new Promise((resolve) => {
        this.$store.dispatch('requestModuleActivation', {
          "company_id": this.company['id_goldylocks'],
          "module_id": this.selectedModule,
          "period_days": this.selectedPeriod,
          "unique_payment_hash": this.activationStartDate
        })
            .then(() => {
              Swal.fire({
                icon: `success`,
                html: `Pedido de ativação enviado.<br><span class="is-size-7">A ativação pode demorar até 5 minutos.</span>`
              })
            })
            .catch(() => {
              Swal.fire({
                icon: `warning`,
                html: `Ocorreu um erro ao processar a ativação!`
              })
            })
      });
    }
  },
  mounted() {
    this.licenseData.name = this.companyDetails.nome_goldylocks
    this.licenseData.description = ''
    this.licenseData.address = this.companyDetails.morada_goldylocks;
    this.licenseData.zipcode = this.companyDetails.cp_goldylocks
    this.licenseData.city = this.companyDetails.localidade_goldylocks
  }
})
