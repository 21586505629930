<template>
  <div class="border-bottom-light">
    <div class="columns">
      <div class="column">
        <!-- info -->
        <div v-if="type === 'info'">
          <span class="fa fa-info-circle has-text-link"></span>&nbsp;
          <span class="has-text-weight-semibold has-text-grey is-uppercase is-size-7">Info</span>
        </div>
        <!-- warning -->
        <div v-if="type === 'warning'">
          <span class="fa fa-exclamation-triangle has-text-warning"></span>&nbsp;
          <span class="has-text-weight-semibold has-text-grey is-uppercase is-size-7">Aviso</span>
        </div>
        <!-- error -->
        <div v-if="type === 'error'">
          <span class="fa fa-times-circle has-text-danger"></span>&nbsp;
          <span class="has-text-weight-semibold has-text-grey is-uppercase is-size-7">Erro</span>
        </div>
        <div>
          <p class="has-text-grey is-size-7">{{ unreadNotification.datahora }}</p>
        </div>
      </div>
      <div class="column is-7">
        <p class="mt-1">
          {{ unreadNotification.notificacao }}
        </p>
        <p>
          <span class="is-size-7 has-text-grey-light">/{{ unreadNotification.alias_goldylocks }}</span>&nbsp;<router-link class="is-size-7" :to="link">Abrir</router-link>
        </p>
      </div>
      <div class="column has-text-right is-2">
        <button class="button is-small is-success is-outlined mt-3 is-fullwidth" :class="{'is-loading':isLoading}" @click="markAsRead">Lida</button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "UnreadNotificationRow",
  props: ['unreadNotification'],
  data() {
    return {
      isLoading: false,
    }
  },
  computed: {
    link() {
      return `/companies/${this.unreadNotification.id_goldylocks}`
    },
    type() {
      return this.unreadNotification.tipo_notificacao
    }
  },
  methods: {
    markAsRead() {
      this.isLoading = true;
      this.$store.dispatch('markAsRead', this.unreadNotification.hash_notificacao)
          .then(() => {
            this.isLoading = false;
            this.$store.dispatch('fetchUnreadNotifications')
          })
    }
  }
})
</script>

<style scoped>
.border-bottom-light {
  border-top: 1px solid #ecf0f1;
}
</style>
