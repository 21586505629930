<template>
  <input :type="type" v-model="currentValue" @keyup.enter="save" class="hidden-input">
  <a class="is-pulled-right is-size-7 hidden-input-save" @click="save">guardar</a>
</template>

<script>
export default {
  name: "hiddenInput",
  props: ['value','type'],
  emits: ['save'],
  data() {
    return {
      currentValue: ""
    }
  },
  methods: {
    save() {
      this.$emit('save', this.currentValue);
    }
  },
  mounted() {
    if (this.value !== "--") {
      this.currentValue = this.value;
    }
  }
}
</script>

<style scoped>
.hidden-input {
  width: 100%;
  border: none;
  border-bottom: 1px solid lightgray;
  padding: 5px 10px;
}

.hidden-input-save{
  padding: 3px 5px;
}
</style>
