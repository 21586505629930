
import {defineComponent} from 'vue'
import UnreadNotificationRow from "@/components/UnreadNotificationRow.vue";

export default defineComponent({
  name: "UserNotificationsTable",
  components: {UnreadNotificationRow},
  computed: {
    unreadNotificationsCount() {
      return this.$store.getters.unreadNotificationsCount
    },
    unreadNotifications() {
      return this.$store.getters.unreadNotifications
    }
  },
  methods: {
    refreshNotifications() {
      this.$store.dispatch('fetchUnreadNotifications');
    }
  },
  mounted() {
    this.refreshNotifications();
  }
})
