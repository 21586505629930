
import {defineComponent} from 'vue'

export default defineComponent({
  name: "PasswordForceCheck",
  props: ['password'],
  data() {
    return {
      minimumChars: 8,
      hasUppercase: false
    }
  },
  computed: {
    progressClass(): string {
      if (this.progressValue < 33) {
        return 'is-danger'
      } else if (this.progressValue < 100) {
        return 'is-warning'
      } else {
        return 'is-primary'
      }
    },
    progressValue(): number {
      return ((this.password.length * 100) / this.minimumChars)
    },
    hasMinimumChars(): boolean {
      return (this.password >= this.minimumChars)
    }
  }
})
