
import {defineComponent} from 'vue'

export default defineComponent({
  name: "Activation",
  data() {
    return {
      isActivating: true,
      isActivated: false,
    }
  },
  methods: {
    activateUser(activationHash: string) {
      fetch(`/api/users/activate`, {
        method: 'POST',
        body: JSON.stringify({
          activation_hash: activationHash
        })
      }).then(res => {
        this.isActivating = false;

        if (res.status === 200) {
          this.isActivated = true;
        }
      })
    }
  },
  mounted() {
    this.activateUser(this.$route.params.hash.toString())
  }
})
