<template>
  <div class="super-centered">
    <div>
      <div class="">
        <img class="logotipo mb-4" src="https://i.imgur.com/Cb4nqcG.png" alt="logo">
      </div>
      <div class="box login-box">
        <form @submit.prevent="recoverPassword" v-if="!showSuccess">
          <p class="is-size-4 block title">Recuperar senha</p>
          <p class="block is-size-7">Insira o endereço de email associado à sua conta e irá receber um link para redefinir
            a sua senha.</p>
          <div class="field">
            <label class="label"></label>
            <div class="control">
              <input required class="input" type="email" placeholder="ex. cliente@exemplo.com" v-model="email">
            </div>
          </div>

          <button class="button is-link is-fullwidth mt-4">Continuar</button>
        </form>
        <div v-else>
          <p class="is-size-4 title">Sucesso!</p>
          <p class="is-size-7">Verifique a sua caixa de correio para instruções sobre como recuperar a sua senha.</p>
        </div>
        <div class="mt-2 has-text-centered">
          <router-link class="is-size-7" to="/login">Regressar ao login</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoverPassword",
  data() {
    return {
      email: "",
      showSuccess: false
    }
  },
  methods: {
    recoverPassword() {
      fetch(`/api/users/sendrecoverpasswordemail`, {
        method: "post",
        body: JSON.stringify({
          email: this.email
        })
      }).then(res => {
        this.showSuccess = (res.status === 200);
      })
    }
  }
}
</script>

<style scoped>
.super-centered {
  width: 100vw;
  height: 100vh;
  background-color: #E9F3FE;

  display: grid;
  place-items: center;
}

.login-box {
  width: 300px;
}

img.logotipo {
  width: 180px;
}
</style>
