<template>
  <div class="sideMenu" :class="{'sidemenu-open':sideMenuOpen,'sidemenu-close':!sideMenuOpen}">
    <router-link to="/dashboard" class="is-hidden-tablet logo-mobile"><img class="mt-6"
                                                                           src="https://i.imgur.com/os46jFi.png" alt="">
    </router-link>
    <a @click="closeMenu" class="is-hidden-tablet menu-close-button"><span
        class="fa fa-times"></span></a>
    <router-link to="/dashboard" class="is-hidden-touch"><img class="mt-6" src="https://i.imgur.com/os46jFi.png" alt="">
    </router-link>
    <div class="menus">
      <p class="mt-6 menu-category">Menu</p>
      <router-link to="/dashboard" :class="{'active':currentPage==='Dashboard'}"><span class="fas fa-th-large"></span>
        Dashboard
      </router-link>
      <router-link to="/companies" :class="{'active':currentPage==='Companies'}"><span class="fas fa-stream"></span>
        Aplicações
      </router-link>
      <p class="mt-5 menu-category" v-if="isReseller">Revenda</p>
      <router-link v-if="isReseller" to="/companies/create" :class="{'active':currentPage==='CreateCompanyForm'}"><span
          class="fas fa-plus"></span> Criar Goldylocks
      </router-link>
      <router-link v-if="isReseller" to="/resellercompanies" :class="{'active':currentPage==='ResellerCompanies'}"><span
          class="fas fa-user-friends"></span> Os meus clientes
      </router-link>
      <p class="mt-5 menu-category" v-if="isAccountant">Contabilidade</p>
      <router-link v-if="isAccountant" to="/companies/create" :class="{'active':currentPage==='CreateCompanyForm'}"><span
          class="fas fa-plus"></span> Criar Goldylocks
      </router-link>
      <router-link v-if="isAccountant" to="/accountantcompanies" :class="{'active':currentPage==='AccountantCompanies'}"><span
          class="fas fa-briefcase"></span> Empresas
      </router-link>
      <p class="mt-5 menu-category">Conta</p>
      <p class="profile-name is-size-7 is-italic">{{ userData.email }}</p>
      <router-link to="/profile" class="mt-2" :class="{'active':currentPage==='Profile'}"><span
          class="fa fa-user-alt"></span> Os meus dados
      </router-link>
      <a @click="logout" href="#" class="link-logout"><span class="fas fa-sign-out-alt"></span> Terminar sessão</a>
      <div class="bottom-margin"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SideMenu",
  computed: {
    currentPage() {
      return this.$store.getters.currentPage;
    },
    userData() {
      return this.$store.getters.userData;
    },
    isReseller() {
      return this.userData.type === "2" || this.userData.type === "1"
    },
    isAccountant() {
      return this.userData.type === "3"
    },
    sideMenuOpen() {
      return this.$store.getters.sideMenuOpen;
    }
  },
  methods: {
    closeMenu() {
      this.$store.commit('toggleMenu');
    },
    logout() {
      // clear search filter preferences
      this.$store.dispatch('clearSearchFilter');

      // clear companies
      this.$store.dispatch('clearData');

      // clear localstorage
      localStorage.clear();

      // open login page
      this.$router.push('/login');
    }
  },
  watch: {
    '$route'(page) {
      this.closeMenu();
      this.$store.commit('currentPage', page.name);
    }
  },
  mounted() {
    this.$store.dispatch('getUserData');
  }
}
</script>

<style scoped>

.sideMenu {
  height: 100%;
  width: 260px;
  padding: 20px 20px;
  background: rgb(2, 0, 36);
  background: linear-gradient(0deg, rgba(2, 0, 36, 1) 0%, rgba(7, 85, 160, 1) 79%, rgba(8, 99, 185, 1) 100%);
}

@media only screen and (max-width: 768px) {
  .sideMenu {
    z-index: 999;
    position: absolute;
    left: -260px;
    overflow-y: scroll;
  }
}

.sidemenu-open {
  transition: all 0.5s ease;
  left: 0;
}

.sidemenu-close {
  transition: all 0.5s ease;
  left: -260px;
}

.sideMenu > .logo-mobile {
  position: absolute;
  top: -35px;
  left: 18px;
}

.sideMenu > .logo-mobile > img {
  height: 30px;
}


.sideMenu > .menus > a {
  color: #A1D0FB;
  display: block;
  padding: 10px 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.sideMenu > .menus > a > svg {
  margin-right: 30px;
}

.sideMenu > .menus > a:hover {
  transition: background-color 0.4s ease, color 0.4s ease;
  background-color: #B9DCFC;
  color: #0755A0;
  font-weight: bold;
}

.sideMenu > .menus > a.active {
  transition: background-color 0.4s ease, color 0.4s ease;
  background-color: #B9DCFC;
  color: #0755A0;
  font-weight: bold;
}

.sideMenu > .menu-close-button {
  color: #ecf0f1;
  position: absolute;
  left: 220px;
  top: 10px;
}

.sidemenu > .menus > .bottom-margin {
  margin-bottom: 60px;
}

.menu-category {
  color: #ecf0f1;
  font-weight: bold;
}

.profile-name {
  overflow-wrap: break-word;
  color: #ecf0f1;
}
</style>
