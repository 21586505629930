<template>
  <p class="has-text-grey-light is-size-5 mt-4">Planos</p>
  <div class="columns is-multiline mt-4">
    <PlanCard @purchase="purchasePlan" class="column is-one-third" :company="company" :plan="plan" v-for="plan in mainPlans" :key="plan.id_plano"></PlanCard>
  </div>
  <p class="has-text-grey-light is-size-5">Extensões</p>
  <div class="columns is-multiline mt-4">
    <PlanCard @purchase="purchasePlan" class="column is-one-third" :company="company" :plan="plan" v-for="plan in extensions" :key="plan.id_plano"></PlanCard>
  </div>
  <PaymentsModal :company="company" :data="paymentsModal"></PaymentsModal>
</template>

<script>
import PlanCard from "@/components/cards/PlanCard";
import PaymentsModal from "@/components/modals/PaymentsModal";

export default {
  name: "PlansTable",
  components: {PlanCard,PaymentsModal},
  props: ['company'],
  data(){
    return {
      paymentsModal: {
        open: false,
        plan: {},
        period: 0
      }
    }
  },
  methods:{
    purchasePlan(plan,period){
      this.paymentsModal.plan = plan;
      this.paymentsModal.period = period;
      this.paymentsModal.open = true;
    }
  },
  computed: {
    mainPlans() {
      return this.$store.getters.mainPlans;
    },
    extensions(){
      return this.$store.getters.extensions;
    }
  },
  mounted() {
    this.$store.dispatch('fetchPlans');
  }
}
</script>

<style scoped>

</style>
