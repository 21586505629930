<template>
  <div class="super-centered">
    <div>
      <div class="">
        <img class="logotipo mb-4" src="https://i.imgur.com/Cb4nqcG.png" alt="logo">
      </div>
      <form class="box login-box" @submit.prevent="login">
        <div class="field">
          <label class="label">Email</label>
          <div class="control">
            <input class="input" type="email" placeholder="ex. cliente@exemplo.com" v-model="email" required>
          </div>
        </div>

        <div class="field">

          <div class="is-flex is-justify-content-space-between">
            <label class="label">Password</label>
            <router-link to="/recoverpassword" class="is-size-7 mt-2">Recuperar senha</router-link>
          </div>
          <div class="control">
            <input class="input" type="password" placeholder="********" v-model="password" required>
          </div>

        </div>

        <div class="mt-5">
          <button class="button is-link is-fullwidth mt-4" :class="{'is-loading':loading}">Continuar</button>
        </div>
      </form>
      <div class="mt-2">
        <p class="is-size-7">Não tem conta?
          <router-link to="/registration" class=" has-text-link">Registe-se</router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      loading: false,
      email: "",
      password: ""
    }
  },
  methods: {
    login() {
      this.loading = true;
      fetch(`/api/users/login`, {
        method: "POST",
        body: JSON.stringify({
          email: this.email,
          password: this.password
        })
      }).then(res => {
        return new Promise(((resolve, reject) => {
          // check login information
          if (res.status === 200) {
            res.json().then(userData => {
              localStorage.setItem('token', userData.token);
              localStorage.setItem('userData', JSON.stringify(userData));

              this.$store.commit('userData', userData);

              this.loading = false;

              this.$router.push('/dashboard');
            });
          } else {
            this.loading = false;
          }
        }))
      })
    }
  },
}
</script>

<style scoped>
.super-centered {
  width: 100vw;
  height: 100vh;
  background-color: #E9F3FE;

  display: grid;
  place-items: center;
}

.login-box {
  width: 300px;
}

img.logotipo {
  width: 180px;
}
</style>
