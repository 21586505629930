<template>
  <div class="super-centered">
    <div>
      <div class="">
        <img class="logotipo mb-4" src="https://i.imgur.com/Cb4nqcG.png" alt="logo">
      </div>
      <div class="box login-box">
        <div v-if="!changeRequested">
          <form @submit.prevent="resetPassword">
            <p class="is-size-4 block title">Redefinir senha</p>
            <p class="block is-size-7">Defina uma nova senha para a sua conta da àrea de cliente Goldylocks.</p>


            <div class="field">
              <label class="label">Insira a nova senha</label>
              <div class="control">
                <input required class="input" type="password" v-model="password">
              </div>
            </div>
            <div>
              <PasswordForceCheck :password="password"></PasswordForceCheck>
              <span v-if="passwordFilled && !enoughPasswordChars" class="is-size-7 has-text-weight-semibold has-text-danger"> Minimo 8 carateres.</span>
            </div>
            <div class="field mt-5">
              <label class="label">Repetir nova senha</label>
              <div class="control">
                <input required class="input" type="password" v-model="passwordConfirmation">
              </div>
            </div>

            <button :disabled="!validPassword" :class="{'is-loading':isRequesting}" class="button is-primary is-fullwidth mt-5">Alterar senha</button>
          </form>
        </div>

        <div v-else>
          <!-- PASSWORD CHANGED -->
          <div v-if="isChanged">
            <div>
              <div class="has-text-centered">
                <p>
                  <span class="fa fa-check fa-4x has-text-success"></span>
                </p>
                <p class="title">Sucesso!</p>
                <p class="mt-1">A sua senha foi redefinida com sucesso!</p>
                <p class="mt-3">
                  <router-link to="/login">Regressar ao login</router-link>
                </p>
              </div>
            </div>
          </div>

          <!-- ERROR CHANGING PASSWORD -->
          <div v-else>
            <div>
              <div class="has-text-centered">
                <p>
                  <span class="fa fa-times-circle fa-4x has-text-danger"></span>
                </p>
                <p class="title">Atenção!</p>
                <p class="mt-1">Não foi possivel alterar a senha.</p>
                <p class="mt-3">
                  <router-link to="/login">Regressar ao login</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordForceCheck from "@/components/PasswordForceCheck.vue";

export default {
  name: "ResetPassword",
  components: {PasswordForceCheck},
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      changeRequested: false,
      isRequesting: false,
      isChanged: false,
    }
  },
  computed: {
    passwordFilled() {
      return (this.password.length > 0)
    },
    enoughPasswordChars() {
      return (this.password.length >= 8)
    },
    validPassword() {
      return (this.password.length >= 8 && this.password === this.passwordConfirmation)
    }
  },
  methods: {
    resetPassword() {
      if (this.validPassword) {

        this.isRequesting = true;

        this.$store.dispatch('setNewPasswordFromRecovery', {
          recoverHash: this.$route.params['hash'],
          newPassword: this.password
        }).then(() => {
          this.isRequesting = false;
          this.changeRequested = true;
          this.isChanged = true;
        }).catch(() => {
          this.isRequesting = false;
          this.changeRequested = true;
          this.isChanged = false;
        })
      }
    }
  }
}
</script>

<style scoped>
.super-centered {
  width: 100vw;
  height: 100vh;
  background-color: #E9F3FE;

  display: grid;
  place-items: center;
}

.login-box {
  width: 300px;
}

img.logotipo {
  width: 180px;
}
</style>
