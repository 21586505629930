<template>
  <div class="modal" :class="{'is-active':data.open}">
    <div class="modal-background"></div>
    <div class="modal-content">
      <div class="container">
        <div class="box">
          <div>
            <p class="is-size-6 has-text-weight-semibold">Subscrever plano</p>
          </div>
          <div class="is-flex is-justify-content-space-between">
            <div>
              <p class="is-size-3 has-text-grey">{{ data.plan.nome_plano }}</p>
              <p class="is-size-7 has-text-grey-dark">Modalidade&nbsp;<span
                  class="has-text-weight-bold">{{ period }}</span></p>
            </div>
            <div class="has-text-right">
              <p><span class="is-size-3 has-text-weight-semibold">{{ value }}</span>&euro;</p>
              <p class="has-text-grey is-size-7">Com impostos: {{ valueWithTaxes }}&euro;</p>
            </div>
          </div>
          <div class="mt-6" v-if="!processingPayment">
            <!-- payment methods -->
            <p class="has-text-grey">Seleccionte método de pagamento</p>
            <div class="columns mt-2 is-gapless">
              <div class="column">
                <button class="button is-fullwidth" @click="stripePayment">Cartão de crédito</button>
              </div>
              <div class="column">
                <button class="button is-fullwidth" :class="{'is-link':paymentType==='multibanco'}"
                        @click="multibancoPayment">Multibanco
                </button>
              </div>
              <div class="column">
                <button class="button is-fullwidth" @click="askMBWayPhone">MBWay</button>
              </div>
            </div>
          </div>
          <!-- Numero de telemovel MBWay -->
          <div v-if="askingMbwayPhone && paymentType === 'mbway'" class="mt-5">
            <div class="columns">
              <div class="column has-text-centered">
                <img src="https://i.imgur.com/hU1oQCZ.png" alt="mbway_logo">
              </div>
              <div class="column has-text-centered">
                <form @submit.prevent="mbWayPayment">
                  <div class="is-flex is-justify-content-space-around">
                    <div class="field">
                      <label class="label">Nº de telemovel para pagamento</label>
                      <div class="control">
                        <input required class="input has-text-centered" type="mobile" placeholder="Ex. 966966966" v-model="mbway.mobile">
                      </div>
                    </div>
                  </div>
                  <button class="mt-2 button is-outlined">Pagar agora</button>
                </form>
              </div>
            </div>
          </div>
          <!-- Notificacao MBWAY -->
          <div class="mt-6 mb-6" v-if="paymentType === 'mbway' && processingPayment">
            <div class="is-flex is-flex-direction-column is-align-items-center">
              <img src="https://i.imgur.com/hU1oQCZ.png" alt="mbway_logo">
              <p class="is-size-4 mt-3">Pedido de pagamento enviado!</p>
              <p class="is-size-6 has-text-grey">Siga as instruções no ecrã do seu telemóvel.</p>
            </div>
          </div>
          <div class="mt-5" v-if="paymentType === 'multibanco'">
            <div class="columns">
              <div class="column">
                <!-- REFERENCIA MULTIBANCO -->
                <img src="https://i.imgur.com/JOQyN65.png" alt="multibanco_logo">
              </div>
              <div class="column">
                <p>Entidade&nbsp;<span class="has-text-weight-semibold is-size-4 ml-2">{{ multibanco.entity }}</span>
                </p>
                <p>Referência&nbsp;<span
                    class="has-text-weight-semibold is-size-4 ml-2">{{ multibanco.reference }}</span></p>
                <p>Valor&nbsp;<span class="has-text-weight-semibold is-size-4 ml-2">{{ multibanco.value }}</span>&euro;
                </p>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <button v-if="!processingPayment" @click="close" class="button is-fullwidth is-danger is-light">Cancelar
              subscrição
            </button>
            <button v-else @click="close" class="button is-fullwidth is-danger is-light">Fechar janela</button>
          </div>
        </div>
      </div>
    </div>
    <!--    <button @click="close" class="modal-close is-large" aria-label="close"></button>-->
  </div>
</template>

<script>
export default {
  name: "PaymentsModal",
  props: ['data', 'company'],
  data() {
    return {
      askingMbwayPhone: false,
      paymentType: "",
      processingPayment: false,
      taxPercentage: 23,
      stripe: {
        public_key: "pk_test_51HTpoDBvpySkNODwEQ89BotsYVusqjs9ZdfSnAPCJ01Jub92VILmjkAi9hz1e8mQDzWiymzAWfb6qF8jtdO7H7wL00YmRXbYVU"
      },
      mbway: {
        mobile: ''
      },
      multibanco: {
        entity: '12345',
        reference: '000 000 000',
        value: '0.00'
      }
    }
  },
  methods: {
    close() {
      this.data.open = false;
      this.processingPayment = false;
      this.paymentType = "";
    },
    requestPaymentHash() {
      return new Promise((resolve) => {
        this.$store.dispatch('requestPaymentHash', {
          "company_id": this.company['id_goldylocks'],
          "plan_id": this.data.plan['id_plano'],
          "period_days": this.data.period
        }).then(paymentHash => {
          resolve(paymentHash)
        });
      });
    },
    multibancoPayment() {
      if (this.paymentType !== "multibanco") {
        this.paymentType = "multibanco";
        this.processingPayment = true;
        this.requestPaymentHash().then(paymentHash => {
          this.$store.dispatch('requestMultibancoPayment', {
            "paymentHash": paymentHash,
            "value": this.valueWithTaxes,
            "mobileNumber": this.profile.mobile,
            "customerEmail": this.profile.email,
            "description": `${this.data.plan['nome_plano']} - ${this.period}`
          }).then(paymentInfo => {
            this.multibanco.entity = paymentInfo.entity;
            this.multibanco.reference = paymentInfo.reference;
            this.multibanco.value = paymentInfo.value;
          })
        })
      }
    },
    askMBWayPhone(){
      this.paymentType = "mbway";
      this.askingMbwayPhone = true;
      this.mbway.mobile = this.profile.mobile;
    },
    mbWayPayment() {
      if (this.hasMobile) {
        this.askingMbwayPhone = false;
        this.paymentType = "mbway";
        this.processingPayment = true;
        this.requestPaymentHash().then(paymentHash => {
          this.$store.dispatch('requestMbWayPayment', {
            "paymentHash": paymentHash,
            "value": this.valueWithTaxes,
            "mobileNumber": this.mbway.mobile,
            "customerEmail": this.profile.email,
            "description": `${this.data.plan['nome_plano']} - ${this.period}`
          })
        })
      }
    },
    stripePayment() {
      this.requestPaymentHash().then(paymentHash => {
        this.$store.dispatch('requestStripePayment', {
          description: `Goldylocks - ${this.data.plan.nome_plano} - ${this.period}`,
          value: parseInt(this.valueWithTaxes * 100),
          paymentHash: paymentHash
        }).then(stripeSessionId => {
          // process Stripe payment
          let stripe = new Stripe(this.$store.getters.stripePublicKey);
          console.log('stripe_session_id', stripeSessionId.id)
          stripe.redirectToCheckout({
            sessionId: stripeSessionId.id
          })
        })
      })
    }
  },
  computed: {
    hasMobile() {
      return (typeof this.profile.mobile !== "undefined");
    },
    profile() {
      return this.$store.getters.profile;
    },
    period() {
      if (this.data.period === 30) {
        return 'Mensal';
      } else {
        return 'Anual';
      }
    },
    value() {
      if (this.data.period === 30) {
        return this.data.plan['valor_mensal'];
      } else {
        return this.data.plan['valor_anual'];
      }
    },
    valueWithTaxes() {
      let taxFloat = (this.taxPercentage / 100) + 1;
      return parseFloat(this.value * taxFloat).toFixed(2);
    }
  },
  mounted() {
    this.$store.dispatch('fetchProfile');
  }
}
</script>

<style scoped>

</style>
