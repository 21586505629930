<template>
  <div class="box">
    <div class="columns">
      <div class="column is-flex">
        <span>
          <span class="far fa-user-circle fa-4x is-hidden-tablet mr-4"></span>
        </span>
        <span>
          <p class="title is-size-4">Editar informação</p>
          <p class="subtitle is-size-6">Altere aqui os seus dados</p>
        </span>
      </div>
      <div class="column has-text-right">
        <span class="far fa-user-circle fa-8x is-hidden-touch"></span>
      </div>
    </div>


    <div class="mt-6">
      <div class="columns is-variable">
        <div class="column is-6">
          <!-- DADOS PESSOAIS -->
          <p class="is-size-5">Dados pessoais</p>
          <form @submit.prevent="saveProfile">
            <div class="field mt-5">
              <label class="label">Nome</label>
              <div class="control">
                <input class="input" type="text" placeholder="" v-model="profile.name" required>
              </div>
            </div>
            <div class="field">
              <label class="label">Morada</label>
              <div class="control">
                <input class="input" type="text" placeholder="" v-model="profile.address">
              </div>
            </div>
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <label class="label">Código Postal</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="" v-model="profile.zipcode">
                  </div>
                </div>
              </div>
              <div class="column is-8">
                <div class="field">
                  <label class="label">Cidade</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="" v-model="profile.city">
                  </div>
                </div>
              </div>
            </div>
            <div class="field mt-6">
              <label class="label">Email</label>
              <div class="control">
                <input class="input" type="email" placeholder="" v-model="profile.email" required>
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <div class="field">
                  <label class="label">Telemóvel</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="" v-model="profile.mobile">
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="field">
                  <label class="label">Telefone</label>
                  <div class="control">
                    <input class="input" type="text" placeholder="" v-model="profile.phone">
                  </div>
                </div>
              </div>
            </div>
            <button class="button is-success" :class="{'is-loading':isSaving}" :disabled="isSaved">
              {{ saveButtonText }}
            </button>
          </form>
        </div>
        <div class="column"></div>
        <div class="column is-4">
          <!-- SENHA -->
          <p class="is-size-4">Alterar senha</p>
          <form @submit.prevent="changePassword">
            <div class="field mt-5">
              <label class="label">Senha atual</label>
              <div class="control">
                <input class="input" :class="{'is-danger':password.invalidCurrent}" type="password" placeholder="" v-model="password.current" required>
              </div>
            </div>
            <div class="field mt-6">
              <label class="label">Nova senha</label>
              <div class="control">
                <input class="input" type="password" placeholder="" v-model="password.new" minlength="6" required>
              </div>
            </div>
            <div class="field">
              <label class="label">Confirmação nova senha</label>
              <div class="control">
                <input class="input" type="password" placeholder="" v-model="password.confirmation" minlength="6"
                       required>
              </div>
            </div>
            <button class="button is-success" :class="{'is-loading':isSavingPassword}" :disabled="isPasswordSaved">
              {{ savePasswordButtonText }}
            </button>
          </form>
        </div>
        <div class="column"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Profile",
  data() {
    return {
      isSaving: false,
      isSavingPassword: false,
      isSaved: false,
      isPasswordSaved: false,
      password: {
        invalidCurrent: false,
        current: '',
        new: '',
        confirmation: ''
      }
    }
  },
  computed: {
    saveButtonText() {
      if (this.isSaved) {
        return 'Dados alterados com sucesso!';
      } else {
        return 'Guardar';
      }
    },
    savePasswordButtonText() {
      if (this.isPasswordSaved) {
        return 'Senha alterada!';
      } else {
        return 'Alterar senha';
      }
    },
    profile() {
      return this.$store.getters.profile;
    }
  },
  methods: {
    validNewPassword() {
      return (this.password.current.length && (this.password.new !== this.password.current) && (this.password.new === this.password.confirmation) && this.password.new.length >= 6);
    },
    enableSaveButton() {
      setTimeout(() => {
        this.isSaved = false;
      }, 2000);
    },
    enablePasswordSaveButton() {
      setTimeout(() => {
        this.isPasswordSaved = false;
      }, 2000);
    },
    saveProfile() {
      this.isSaving = true;
      this.$store.dispatch('saveProfile')
          .then(() => {
            this.isSaved = true;
            this.isSaving = false;
            this.enableSaveButton();
          })
    },
    clearPasswords() {
      this.password = {
        invalidCurrent: false,
        current: '',
        new: '',
        confirmation: ''
      }
    },
    changePassword() {
      if (this.validNewPassword()) {
        this.isSavingPassword = true;
        this.$store.dispatch('changePassword', {
          newPassword: this.password.new,
          oldPassword: this.password.current
        }).then(() => {
          // password changed
          this.password.invalidCurrent = false;
          this.isPasswordSaved = true;
          this.isSavingPassword = false;
          this.enablePasswordSaveButton();
          this.clearPasswords();
        })
        .catch(()=>{
          // password not changed
          this.isSavingPassword = false;
          this.password.invalidCurrent = true;
        });
      }
    }
  },
  mounted() {
    this.$store.dispatch('fetchProfile');
  }
}
</script>

<style scoped>

</style>
